import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Img404 from '../images/404@2x.png'
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Molly - Simple Applicant Tracking Software for Startups" />

    <div className="text-center">
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <main role="main" className="inner cover">
          <h1 className="cover-heading mt-5 mb-5">Oops!</h1>
          <img width={288} src={Img404}/>
          <p className="lead mt-5">
            We can't seem to find the page you are looking for :(
          </p>
          <p className="lead">
              <Link to="/">Go to homepage?</Link>
          </p>
        </main>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
